body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url(./assets/fonts/Open_Sans/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(./assets/fonts/Inter/Inter-Regular.ttf) format("truetype");
}

.mr-20 {
  margin-right: 20px;
}

.custom-tooltip-label
{
  font-weight: bold;
}

.custom-tooltip-item
{
  font-size: 95%;
}

.custom-tooltip{
  background-color: #fff;
  margin: 10,10,10,10;
  border: 1px;
  border-style: solid;
  border-color:rgba(169, 169, 169, 0.5);
}

.cookies-bar {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  bottom: 0;
  width: 100%;
  background-color: #EEEEEE;
}

.grecaptcha-badge { visibility: hidden; }

.cookies-bar-text {
  line-height: 150%;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  padding: 0px 10px;
  text-align: left;
  color: #000000;
}

.custom-dropdown {
  float: left;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: white;
  text-align: left;
  border: 1px solid transparent;
}

.hyperlink-underline {
  text-underline-position: below;
  text-decoration: underline;
  text-decoration-color: #000000;
  color: #000000;
}

.min-width-140{
  min-width:140px !important;
}

.btn-pagination {
  background-color: white !important;
  border-color: #7a878e !important;
}

.btn-pagination-active {
  color: white !important;
  background-color: #25476a !important;
}

.markdown-text {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.modal-centered {
  position: fixed;
  height: 1px;
  width: 1px;
  left: 5%;
}

.dropdown-item{
  width: 100% !important;
}

.modal-text-align {
  text-align: left;
}

.confirm-alert {
  font-family: tahoma;
  font-size: 22px;
  text-align: center;
  width: 400px;
  padding: 20px;
  padding-top: 10px;
  background: #fff;
  box-shadow: 0 20px 75px rgba(37, 71, 106, 0.23);
  color: #25476a;
}

.confirm-alert > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #25476a;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #25476a;
  font-size: 14px;
}

.input-field {
  padding-left: 11px !important;
  margin-left: -11px;
}

.date-input-field {
  width: 66px !important;
}

.dropdown-input {
  margin-left: -11px;
}

.buil-comments {
  width: 376px;
}

.ql-editor {
  min-height: 110px;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0 !important;
  padding: 0 !important;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
  box-shadow: none !important;
}

.buildComments {
  max-width: 500px;
  word-wrap: break-word;
}

.buildComments > p:first-child {
  margin-top: unset;
}

.downloadBuildComments {
  word-wrap: break-word;
}

.downloadBuildComments > p:first-child {
  margin-top: unset;
}

.no-padding-bottom {
  padding-bottom: 0px !important;
}

.new-line {
  white-space: pre-line;
}

/* MODALS */
/* -------------------------- */
.modal-title {
  margin-top: unset;
  font-weight: 300;
}

.modal-dialog {
  margin-top: 200px !important;
}

.modal-header > .close {
  cursor: pointer;
}

.groupsModal {
  width: 200px !important;
}

.ipFilterModal {
  width: 410px !important;
}
/* -------------------------- */

.mainnav-toggle {
  cursor: pointer;
  color: #fff;
  padding: 0 12px;
  display: table-cell;
  vertical-align: middle;
  height: 59px;
  transition: all 0.4s;
}

.react-confirm-alert-overlay {
  z-index: 9999 !important;
}

@media (min-width: 768px) and (max-width: 991px) {
  #content-container {
    padding-left: 220px !important;
    left: unset !important;
  }
}
