#groups_modal {
  .modal-content {
    border-radius: 0;

    .modal-header {
      background-color: $primary-bg;
      border-radius: 0;

      .modal-title {
        margin-top: 0;
        color: $white;
      }

      button.close {
        top: 0;
        color: $white;
      }
    }

    .modal-body {
      min-width: 200px;
    }
  }
}
