.sorting {
  i {
    cursor: pointer;
  }

  .down,
  .up {
    color: #000;
  }

  .down:hover,
  .up:hover {
    text-decoration: none;
  }

  .up.active i,
  .down.active i {
    font-weight: bold;
  }

  .up.active,
  .down.active,
  i:hover {
    color: $primary;
    font-weight: bold;
  }

  a:last-child {
    margin-left: -7px;
  }
}
