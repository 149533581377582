// GENERAL LAYOUT VARIABLES
// ============================================
$standalone-color-scheme    : true !default;



// Fonts
// --------------------------------------------
$font-family-base           : 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size-base             : 13px;
$font-semibold              : 600;


// Default background and text color
// --------------------------------------------
$body-bg                    : #ecf0f5;
$body-color                 : #7a878e;
$body-main-color            : #4d627b;


// Boxed layout
// --------------------------------------------
$boxed-top-margin           : 45px;
$boxed-btm-margin           : 0;
$boxed-width                : 1128px;
$boxed-width-hd             : 1280px; // Max boxed width for monitors support more than 1920x1200 resolution.
$boxed-bg                   : #637a8b;
$boxed-bg-img               : 'none'; // Set the boxed background image. EX: '../img/landscape.jpg';


// Links color
// --------------------------------------------
$link-color                 : $body-color;
$link-hover-color           : darken($body-color, 5%);


// Border and grid gutter
// --------------------------------------------
$border-color-base          : rgba(0,0,0,.07);
$border-radius-base         : 3px;
$grid-gutter-width          : 20px;


// Components Active State
// --------------------------------------------
$state-active-bg            : #1976d2;
$state-active-color         : #fff;


// Text Selections
// --------------------------------------------
$selection-bg               : $state-active-bg;
$selection-color            : $state-active-color;




// CLEAN ZONE (LOGIN, REGISTER, AND ERROR PAGES CONTAINER)
//============================================
$cls-bg                     : $body-bg;
$cls-color                  : $body-main-color;




// NAVIGATION
//============================================
$mainnav-bg                 : #263238;
$mainnav-color              : #abb1b7;
$mainnav-hover-color        : $state-active-color;
$mainnav-active-bg          : transparent;
$mainnav-active-color       : #fff;
$mainnav-active-state       : $state-active-bg;
$mainnav-lg-width           : 220px;
$mainnav-sm-width           : 55px;
$mainnav-profile-img        : 'none'; //'../img/nav-profile.png'; // Disabled the background image by add 'none' to the value.
$mainnav-profile-bg         : $mainnav-bg;
$mainnav-profile-color      : $mainnav-color;


// Reduce the file size by disabling features that you do not use.
$mainnav-collapse           : true;
$mainnav-slide              : true;
$mainnav-reveal             : true;




// NAVBAR
//============================================
$navbar-bg                  : #fff;
$navbar-color               : $body-color;
$brand-bg                   : transparent;
$brand-bg-overlay           : $mainnav-bg;
$brand-color                : $state-active-color;
$brand-color-overlay        : $navbar-color;
$brand-font-size            : 19px;
$navbar-height              : 59px;




// ASIDE
//============================================
$aside-width                : 255px;
$aside-dark-bg              : #34495e;
$aside-dark-color           : #ffffff;
$aside-bright-bg            : #ffffff;
$aside-bright-color         : $body-color;
$aside-tabs                 : true;




// CONTENT - FIXEDBAR (AN ADDITIONAL SIDEBAR)
// ============================================
$fixedbar                   : true;
$fixedbar-width             : 240px;
$fixedbar-bg                : #fff;
$fixedbar-color             : $body-color;





// FOOTER
//============================================
$footer-bg                  : lighten($body-bg, 5%);
$footer-color               : $body-main-color;




// ANIMATION
//============================================
$enable-animation           : true;
$enable-easeInQuart         : true;
$enable-easeOutQuart        : true;
$enable-easeInBack          : true;
$enable-easeOutBack         : true;
$enable-easeInOutBack       : true;
$enable-steps               : true;
$enable-jumping             : true;
$enable-rubber              : true;


// Animation duration
// --------------------------------------------
$ease-duration              : .35s;
$easeInQuart                : .35s;
$easeOutQuart               : .5s;
$easeInBack                 : .35s;
$easeOutBack                : .35s;
$easeInOutBack              : .5s;
$steps                      : .5s;
$jumping                    : .7s;
$rubber                     : .5s;





// COMPONENTS
//============================================
// Reduce the file size by disabling the color of the components that you do not use.
// --------------------------------------------
$enable-mint                : true;
$enable-purple              : true;
$enable-pink                : true;
$enable-dark                : true;


// Background Colors
// --------------------------------------------
$light-bg                   : #ffffff;
$gray-bg                    : #e9eeef;
$primary-bg                 : $state-active-bg;
$info-bg                    : #03a9f4;
$success-bg                 : #8bc34a;
$warning-bg                 : #ffb300;
$danger-bg                  : #f44336;
$mint-bg                    : #26a69a;
$purple-bg                  : #ab47bc;
$pink-bg                    : #f06292;
$dark-bg                    : #3a444e;


// Text Colors
// --------------------------------------------
$text-muted                 : lighten($body-color, 10%);
$light-color                : $body-color;
$gray-color                 : $body-color;
$primary-color              : $state-active-color;
$info-color                 : #ffffff;
$success-color              : #ffffff;
$warning-color              : #ffffff;
$danger-color               : #ffffff;
$mint-color                 : #ffffff;
$purple-color               : #ffffff;
$pink-color                 : #ffffff;
$dark-color                 : #ffffff;







// OVERWRITE BOOTSTRAP COMPONENT
// ============================================
// Please disabled components that you do not use.

$table                      : true;
$form-control               : true;
$nav-pills                  : true;
$list-group                 : true;
$label                      : true;
$badge                      : true;
$breadcrumb                 : true;
$alert                      : true;
$modal                      : true;
$popover                    : true;
$dropdown                   : true;
$well                       : true;
$progressbar                : true;
$pager                      : true;
$pagination                 : true;
$carousel                   : true;
$accordion                  : true;
$tabs                       : true;
$bg                         : true;
$txt                        : true;


// Components State
// --------------------------------------------
$state-success              : darken($success-bg, 10%);
$state-warning              : darken($warning-bg, 10%);
$state-danger               : darken($danger-bg, 10%);


// Tooltip
// --------------------------------------------
$tooltip                    : true;
$tooltip-bg                 : $dark-bg;


// Buttons
// --------------------------------------------
$buttons                    : true;
$include-btn-hover          : true;
$include-btn-active         : true;
$include-btn-labeled        : true;
$include-btn-circle         : true;
$include-btn-icon           : true;
$include-btn-rounded        : true;


// Panels
// --------------------------------------------
$panels                     : true;
$panel-default-head-color   : $body-main-color;
$panel-heading-height       : 42px;
$panel-w-tabs               : true;
$panel-w-progressbar        : true;
$panel-w-pagination         : true;
$panel-w-pager              : true;
$panel-w-btn                : true;







// EXTRA COMPONENTS
// ============================================
$megamenu                   : true;
$invoice                    : true;
$file-manager               : true;
$tasklist                   : true;
$comments                   : true;
$blog                       : true;
$pricing-tables             : true;



// Chat
// --------------------------------------------
$chat                       : true;
$chat-bg                    : #d6dbe0;
$chat-primary-speech-bg     : $info-bg;
$chat-primary-speech-color  : #ffffff;
$chat-secondary-speech-bg   : #ffffff;
$chat-secondary-speech-color: #575757;




// Timeline
// --------------------------------------------
$timeline                   : true;
$timeline-bg                : #fff;
$timeline-color             : $body-color;
$timeline-border-color      : rgba(0,0,0,.1);




// Email
// --------------------------------------------
$email                      : true;
$email-list-odd             : rgba(0, 0, 0, 0.01);
$email-list-hover           : rgba(0, 0, 0, 0.05);
$email-highlight            : #f2f0c2;
$email-stared-color         : #ffa100;
$email-stared-hover         : lighten($email-stared-color,15%);
$email-unstared-color       : #d3d7da;




// Form Wizard
// --------------------------------------------
$form-wizard                : true;
$include-form-wizard-classic: true;
$include-form-wizard-steps  : true;




// Toggle Switch Plugin Stylesheet
// --------------------------------------------
$toggle-switch                              : true;
$toggle-switch-container-width              : 30px;
$toggle-switch-container-height             : 16px;
$toggle-switch-container-border-width       : 0;
$toggle-switch-container-bg                 : #afafaf;
$toggle-switch-container-bg-checked         : $success-bg;
$toggle-switch-container-border-color       : transparent;
$toggle-switch-container-box-shadow         : 0;
$toggle-switch-container-transition-duration: .3s;
$toggle-switch-button-margin                : 1px;
$toggle-switch-button-bg                    : #fff;
$toggle-switch-button-box-shadow            : 0 2px 5px 0 fade(#333, 25%);
$toggle-switch-button-transition-duration   : .3s;
$toggle-switch-label-margin-left            : 10px;
$toggle-switch-label-margin-right           : 0;
$toggle-switch-opacity-disabled             : 0.3;




// Magic check (Checkbox and radio) plugins
// --------------------------------------------
$magic-check                : true;
$magic-material-style       : true;
$magic-radio-size           : 17px;
$magic-checkbox-size        : 17px;
$magic-dot-size             : 9px;
$magic-main-color           : $state-active-bg;
$magic-border-color         : rgba(0,0,0,0.15);




// Exclusive Plugin Stylesheet
// --------------------------------------------
$nifty-scrollTop             : true;
$nifty-language-selector     : true;
$nifty-overlay               : true;
$nifty-noty                  : true;
$nifty-check                 : true;
$nifty-check-bg              : transparent;
$nifty-check-color           : #515151;
$nifty-check-border          : #5f5f5f;
$nifty-check-border-radius   : $border-radius-base;




// 3RD PARTY PLUGINS
//============================================
$nestable-list              : true;
$jstree                     : true;
$xeditable                  : true;
$full-calendar              : true;
$select2                    : true;
$chosen                     : true;
$bootstrap-select           : true;
$bootstrap-timepicker       : true;
$summernote                 : true;
$bootstrap-markdown         : true;
$datatable                  : true;
$datepicker                 : true;
$noui-slider                : true;
$switchery                  : true;





// GLOBAL MIXINS
// ============================================
// Transform
// --------------------------------------------
@mixin transform($transform){
    -webkit-transform: $transform;
    transform: $transform;
}


// Transitions
// --------------------------------------------
@mixin transition($transition...){
    transition: $transition;
}



// Selection
// --------------------------------------------
@mixin selection {
  ::selection {@content;}
  ::-moz-selection {@content;}
}



// Placeholder
// --------------------------------------------
@mixin placeholder {
    @at-root{
       #{&}::placeholder { @content;}
       #{&}::-webkit-input-placeholder { @content;}
       #{&}::-ms-input-placeholder { @content;}
       #{&}:-ms-input-placeholder { @content;}
    }
}
