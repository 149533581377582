.uncell{
    display: flex;
    flex-direction: row;
    justify-content: center;

    
    p, li {
        font-size: 16px;
        font-weight: 400;
        color: #222222;
        line-height: 1.75;
        box-sizing: border-box;
        font-family: "Roboto";
    }

    h2 {
        color: #222222;
        font-family: "Poppins";
        font-size: 29px;
        line-height: 1.2;
        margin: 27px 0 0 0;
        font-weight: bold;
    }

    img {
        height: 100%;
        width: 100%;
    }

}

.header-image {
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    height: 200px;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    max-width: 804px; //A bit random, but this matches the article width as pulled from WP. Hopefully can replace with something sane later
}

.header-container {
    display: block;
    box-sizing: border-box; 
    height: 100%;
    border-radius: 20px;
    padding: 20px;
}

.header-fade-enabled {
    background-color: rgba(66,74,96,0.55); 
}

.header-fade-disabled {
    background-color: #F4F4F4; 
}

.header-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-text {
    font-family: Poppins;
    font-weight: 600;
    color: white;
    text-align: center;
}