@import "bootstrap";
@import "theme/nifty.scss";
@import "~material-design-icons/iconfont/material-icons.css";
@import "~noty/src/noty";
@import "~noty/src/themes/bootstrap-v4";

@import "partials/cards";
@import "partials/sorting";
@import "partials/filters";
@import "partials/groups_modal";
@import "partials/articles";
@import "partials/loader";

@import "theme/premium-line-icons.css";
@import "grid";

body, html {
  height: 100%;
}

body {
  margin: 0;
}

.column-copy {
  text-align: right;
}

.jumbotron {
  min-width: 50%;
}

#mainnav-container {
  padding-top: 60px;
}

.navbar-brand > img {
  margin-top: 14px;
  margin-left: 10px;
}

.filters-row {
  .form-control {
    height: 19px;
    width: auto;
  }

  th {
    border-bottom: none !important;
  }
}

.table {
  border-collapse: collapse;

  thead > tr:first-child {
    th {
      border-top: none;
    }
  }

  tbody .btn-primary {
    background-color: transparent;
    color: lighten($primary-bg, 3%);
  }
}

.btn-outline-secondary:hover {
  color: #fff !important;
}

.btn-secondary,
.btn-outline-secondary {
  border-color: $body-color;
}

.nav {
  margin-top: 0;
}

.panel {
  background-color: $white;
}

.tgl-menu-btn > .mainnav-toggle > i {
  font-size: 26px;
}

.page-item.active .page-link {
  background-color: #25476a;
  border-color: #25476a;
}

.panel-footer {
  .row > div {
    padding: 0;
  }
}

.pagination {
  margin: 0;
}

.dropdown-item {
  width: auto;
}

.row-button {
  margin-bottom: 1px;
}

.form-group {
  .custom-file {
    width: 376px;
  }

  .form-control {
    width: 350px;
  }

  .form-inline {
    .form-control {
      width: auto;
    }
  }
}

.form-check {
  padding-top: 15px;
  padding-left: 1.5rem;

  .form-check-input {
    margin-top: 0;
  }
}

fieldset.form-group {
  margin-top: 15px;
  border: none;

  legend {
    padding-left: 0 !important;
  }

  .form-check {
    padding-left: 0.8rem;
  }
}

.jumbotron {
  padding-top: 4rem;
  padding-bottom: 4rem;

  .form-group > label {
    margin-bottom: 5px;
    display: inline-block;
  }
}

.custom-file-label::after,
.custom-file-label,
.form-control:not(select) {
  padding: 6px 12px;
  height: 19px;
}

#cke_build_comment {
  width: 374px;
}

input,
textarea,
.custom-file-label {
  border-color: rgba(0, 0, 0, 0.3) !important;
}

@include media-breakpoint-up(sm) {
  .jumbotron {
    min-width: 350px !important;
  }

  .navbar-top-links:first-child {
    margin-left: -15px;
  }
}

.forgot-link {
  text-decoration: underline;
}

#dropdown-user .ic-user {
  > span {
    font-size: 13px;
  }
  > i {
    font-size: 16px;
  }
}

.dropdown-scroll
{
  max-height: 30vh;
  overflow-y: auto;
}

.bg-light-danger {
  background-color: #ff002221 !important;
}

#assign_license_assignment_type {
  margin-bottom: 20px;

  > .form-check {
    display: inline-block;
    margin-right: 10px;

    input[type=radio] {
      margin-top: 3px;
    }
  }
}

.kpi-row {
  .panel {
    min-height: 139px;
  }
}

.scrollable-modal-body {
  max-height: 50vh;
  overflow-y: auto;
}

.custom-control-label{
  padding-top: 4px;
}

.custom-switch .custom-control-label::after{
  margin-top: 1px;
  margin-left: 1px;
}

.custom-control-input:checked ~ .custom-control-label::before{
  background-color: black;
  border-color: black;
}

.custom-switch .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(155, 155, 155, 0.5);
  border-color: rgb(155, 155, 155);
}