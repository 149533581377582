/* NAVIGATION */
/*======================================*/
#mainnav-container{
    position: absolute;
    width: $mainnav-lg-width;
    left: -$mainnav-lg-width;
    top: 0;
    bottom: 0;
    z-index: 1001;
    min-height: 100%;
    margin: 0 auto;
}
#mainnav{
    height: 100%;
    background-color: $mainnav-bg;
    color: $mainnav-color;
    .list-header{
        font-size: .88em;
        font-weight: 700;
        color: darken($mainnav-color, 20%);
        text-transform: uppercase;
        &:not(:first-child){
            margin-top: 35px;
        }
    }
    .list-divider{
        border-bottom: 1px solid $border-color-base;
        margin: 8px 0;
    }
}
#mainnav-menu {
    > .list-divider{
        margin: 17px 0 15px;
        + .list-header{
            margin-top: 0
        }
    }
}
#mainnav-menu-wrap{
    height: 100%
}



/* BACKDROP */
/*======================================*/
.mainnav-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.0);
    transition: background-color $ease-duration;
    z-index: 10000;
}
#container.mainnav-in{
    .mainnav-backdrop{
        bottom: 0;
        background-color: rgba(0,0,0,0.75);
        transition: background-color $ease-duration;
    }
    #mainnav-container{
        z-index: 11000
    }
}
@media (min-width: 768px){
    #container.mainnav-in #mainnav-container{
        z-index: 4000
    }
}


/* BRAND LOGO & TEXT */
/*======================================*/
.mainnav-brand{
    > .brand{
        display: inline-block;
        > .brand-icon, > .brand-text{
            display: inline-block;
            vertical-align: middle;
            float: none
        }
    }
    > .mainnav-toggle{
        display: inline-block;
        padding: 10px;
        float: right;
        position: relative;
        top: 50%;
        margin-top: 0.5em
    }
}



/* PROFILE WIDGET */
/*======================================*/
.mainnav-profile{
    color: $mainnav-profile-color;
    opacity: 1;
    margin-bottom: 7px;
    max-height: 350px;
    overflow: hidden;
    .mnp-name{
        color: $mainnav-profile-color;
        margin: 0;
        font-size: 1em;
        font-weight: 700
    }
    .dropdown-caret, .mnp-desc{
        font-size: .9em;
        color: $mainnav-profile-color;
        opacity: .75
    }
    .profile-wrap{
        padding: 30px 20px 12px;
        background-color: $mainnav-profile-bg;
        @if $mainnav-profile-img != 'none' {
            background-image: url($mainnav-profile-img);
            background-size: cover
        }
    }
}

#mainnav .mainnav-profile{
    .list-group{
        background-color: darken($mainnav-profile-bg, 2%);
        margin: 0;
        &-item{
            color: $mainnav-profile-color;
            padding: 12px 20px;
            &:hover{
                color: darken($mainnav-profile-color, 15%);
                background-color: transparent
            }
        }
    }
}


@media (min-width: 768px){
    #mainnav-container{
        padding-top: $navbar-height;
    }
    #container.mainnav-in{
        .mainnav-backdrop{
            display: none
        }
        #mainnav{
            position: static;
        }
    }
    .mainnav-brand{
        display: none;
    }
    .mainnav-sm{
        .mainnav-profile{
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            margin: 0;
        }
    }
}



/* NAVIGATION MENU */
/*======================================*/

#mainnav-menu ul, .mainnav-widget ul, .menu-popover ul, .menu-popover .sub-menu ul{
    list-style: none;
    padding-left: 0;
}

#mainnav li{
    .arrow {
        float: right;
        line-height: 1.15;
        padding-right: 0
    }
    .arrow:before {
        content: '';
        border-style: solid;
        border-width: 0.1em 0.1em 0 0;
        display: inline-block;
        height: 0.4em;
        left: 0;
        position: relative;
        top: 0;
        width: 0.4em;
        @include transform(rotate(45deg));
    }

    &.active > a > .arrow:before{
        @include transform(rotate(135deg));
    }

    .arrow:before{
        @include transition(all .3s);
    }
}


#mainnav-menu{
    a {
        display: block;
        color: inherit;
        padding: 12px 18px;
        strong{
            font-weight: $font-semibold
        }
        .badge, .label{
            line-height: 1.15em
        }
    }
    >li{
        >a{
            &:hover, &:active{
                color: $mainnav-hover-color;
            }
            &.hover{
                color: $mainnav-hover-color;
                background-color: lighten($mainnav-bg,3%);
            }
        }
    }
    > .active-link{
        >a, >a:hover{
            color: $mainnav-active-color;
            padding-left: 20px;
            font-weight: $font-semibold;
            background-color: $mainnav-active-state;
            margin: 0 5px;
            padding-left: 13px;
            padding-right: 13px;
            border-radius: 3px;
            i{
                color: $mainnav-active-color;
            }
        }
    }
    > .active-sub {
        > a, >a:hover{
            color: $mainnav-active-color;
            font-weight: $font-semibold;
            background-color: $mainnav-active-state;
            margin: 0 5px;
            padding-left: 13px;
            padding-right: 13px;
            border-radius: 3px;
        }
        .active-sub {
            >a, >a:hover{
                color: $mainnav-hover-color;
                font-weight: 700;
                > .arrow:before{
                    border-width: 0.15em 0.15em 0 0
                }
            }
        }
    }
    .active:not(.active-sub) > a{
        color: $mainnav-hover-color;
    }
    > .active {
        background-color: darken($mainnav-bg, 2%);
        .active{
            background-color: darken($mainnav-bg, 4%);
        }
    }
    ul{
        .list-header{
            padding: 10px 20px 12px 55px;
        }
        ul{
            a{
                padding: 10px 20px 12px 65px;
            }
        }
    }
    i{
        font-size: 15px;
        padding: 0 10px 0 0;
        vertical-align: middle
    }
    .hide-lg{
        display: none;
    }
}

#mainnav-menu, .menu-popover .sub-menu{
    ul{
        a{
            padding: 10px 20px 12px 53px;
            &:hover{
                color: $mainnav-hover-color
            }
        }
        ul{
            a:hover{
                color: $mainnav-hover-color
            }
        }
        .active-link{
            a{
                font-weight: 700;
                color: $mainnav-hover-color;
            }
        }
    }
}

@media (min-width: 1200px){
    #mainnav-menu, .menu-popover .sub-menu{
        ul{
            a{
                padding: 8px 20px 8px 53px;
            }
            ul a{
                padding: 8px 20px 8px 65px;
            }
        }
    }
}

@if $mainnav-collapse == true{
    #container.mainnav-sm{
        #mainnav-menu{
            >.active{
                &-link, &-sub{
                    padding: 4px;
                    > a, a:hover{
                        border-radius: $border-radius-base;
                        background-color: $mainnav-active-state;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}




/* NAVIGATION - SHORTCUT BUTTONS */
/*======================================*/
#mainnav-shortcut{
    overflow: hidden;
    margin-top: 15px;
    margin-bottom: 10px;
    ul{
        width: 100%;
        margin: 0;
        padding: 0
    }
    li{
        padding: 0;
        vertical-align: middle;
        text-align: center
    }
    .shortcut-grid{
        display: table;
        border: 0;
        width: 100%;
        height: 1.7em;
        color: $mainnav-color;
        >i{
            display: table-cell;
            vertical-align: middle;
            font-size: $font-size-base;
        }
        >span{
            display: table-cell;
            vertical-align: middle
        }
    }
    .shortcut-wrap .shortcut-grid{
        &, &:hover {
            > .icon-wrap{
                transition: all .3s;
            }
        }
        &:hover{
            > .icon-wrap{
                background-color: $state-active-bg;
                color: $state-active-color;
            }
        }
    }
}

@if $mainnav-collapse == true{
    @media (min-width: 768px){
        #container:not(.mainnav-sm) {
            #mainnav-shortcut{
                margin-top: 15px;
                margin-bottom: 10px;
            }
        }
        #container.mainnav-sm{
            #mainnav-shortcut{
                max-height: 250px;
                .shortcut-wrap{
                    .shortcut-grid{
                        padding: 2px ($mainnav-sm-width - ($font-size-base) - 7) / 4;
                        .icon-wrap{
                            transform: translateY(5px)
                        }
                    }
                }
                >ul{
                    >li{
                        width: 100%;
                        text-align: left;
                        .shortcut-grid{
                            height: auto;
                            display: block;
                            padding: 12px ($mainnav-sm-width - ($font-size-base)) / 2 ;
                        }
                        i{
                            font-size: $font-size-base;
                            line-height: 1.42857;
                        }
                    }
                }
                .shortcut-grid{
                    >.hide-sm{
                        display: none
                    }
                }
            }
        }
    }
}








/* NAVIGATION - WIDGET */
/*======================================*/
.mainnav-widget{
    color: $mainnav-color;
    &-content{
        padding: 10px 15px
    }
    .show-small{
        a{
            display: none
        }
    }
}









/* NAVIGATION - FIXED POSITION */
/*======================================*/
#container.mainnav-fixed{
    #mainnav-container{
        position: fixed
    }
    #mainnav{
        height: 100%;
        height: 100vh;
        .nano-content{
            position: absolute
        }
    }
}

@media (min-width: 768px){
    #container.mainnav-fixed{
        &.mainnav-lg #mainnav-menu-wrap{
            height: 100%;
        }
        &.mainnav-sm{
            #mainnav-menu-wrap{
                height: 100%;
            }

        }
    }
    #container.mainnav-sm{
        #mainnav{
            .menu-popover{
                .nano{
                    >.nano-content{
                        top: auto;
                        max-height: 100%;
                        position: absolute;
                    }
                    &-content{
                        position: static
                    }
                }
            }
        }
    }
}



/* MAIN NAVIGATION - AFFIX */
/*======================================*/
@media (min-width: 760px){
    #container.mainnav-fixed:not(.navbar-fixed){
        #mainnav-container.affix{
            top: 0 - $navbar-height;
            position: fixed;
            &-top{
                top: 0;
                position: absolute;
                #mainnav-menu-wrap > .nano >.nano-content {
                    bottom: $navbar-height;
                }
            }
        }
    }
}

@media (min-width: 1200px){
    #container.mainnav-fixed.navbar-fixed.boxed-layout #mainnav-container{
        padding-top: $navbar-height + $boxed-top-margin;
    }
}





/* MAIN NAVIGATION - EXPANDING  */
/* For large screen */
/*======================================*/
@media (min-width: 992px){
    #container.mainnav-lg{
        #mainnav-container{
            left: 0
        }
        #footer, #content-container{
            padding-left: $mainnav-lg-width;
        }
    }
}





/* Expanding with off canvas mode */
/*======================================*/
@media (min-width: 768px) and (max-width: 991px){
    #container.mainnav-lg{
        #content-container, #footer{
            left: $mainnav-lg-width;
        }
        .tgl-menu-btn{
            right: $mainnav-lg-width;
        }
        #mainnav-container{
            left: 0;
        }
        #mainnav-menu-wrap{
            height: 100%;
        }
    }
}






@if $mainnav-collapse == true{

    /* NAVIGATION - COLLAPSING  */
    /*======================================*/
    $shortcutHeight: 0 - ($font-size-base * 1.42857) - 24;

    .menu-popover.popover{
        > .popover-title, > .popover-content > .sub-menu > .nano-content, > .popover-content > .single-content{
            box-shadow: 10px 0 20px -5px rgba(0,0,0, .15) !important
        }
    }
    @media (min-width: 768px){
        #container.mainnav-sm{
            .brand-icon{
                width: $navbar-height;
                height: $navbar-height
            }
            ul:not(.shortcut-wrap) .shortcut-grid:hover,
            .popover.mainnav-shortcut{
                background-color: lighten($mainnav-bg, 3%);
                color: $mainnav-hover-color;
            }
            .popover.mainnav-shortcut{
                box-shadow: 10px 0 20px -5px rgba(0,0,0, .15) !important
            }
            #mainnav-menu{
                .list-header, .list-divider, .menu-title, .arrow, .menu-title > .label, .menu-title > .badge{
                    display: none;
                }
                .hover {
                    position: relative;
                    > .arrow{
                        display: inline;
                        position: absolute;
                        margin-left: 5px;
                        &:before{
                            @include transform(rotate(45deg))
                        }
                    }
                }
                a{
                    white-space: nowrap;
                    position: relative;
                    padding: 12px 0;
                    margin: 0;
                    border-radius: 0;
                    text-align: center;
                    .badge{
                        margin-top: -1.5em;
                    }
                    .badge{
                        position: absolute;
                        padding: 3px 7px;
                        right: 2px;
                        top: 25%;
                    }
                    i{
                        display: inline;
                        padding: 0
                    }
                }
                .active-link, .active-sub{
                    > a{
                        color: $state-active-color;
                    }
                }
                .collapse{
                    display: none;
                    height: 0 !important;
                }
            }
            #mainnav-container, #navbar .navbar-brand{
                width: $mainnav-sm-width;
                left: 0;
            }
            #navbar{
                .navbar-content{
                    margin-left: $mainnav-sm-width;
                }
            }
            #content-container, #footer{
                padding-left: $mainnav-sm-width;
                left: 0;
            }

            #mainnav{
                position: relative;
                .mainnav-widget{
                    >.show-small{
                        a{
                            display: block;
                            color: $mainnav-color;
                            padding: 12px 0 12px max(0, ($mainnav-sm-width - ($font-size-base)) / 2 );
                            &:hover{
                                color: $mainnav-hover-color;
                                transition: background-color .2s, padding-left .3s, box-shadow .4s;
                            }
                        }
                    }
                    >.hide-small{
                        display: none
                    }
                    .hover{
                        color: $mainnav-hover-color;
                        background-color: lighten($mainnav-bg, 3%);
                    }
                }
            }

        }

        .top-auto{
            top: auto !important;
        }

        /* NAVIGATION - POPOVER  */
        /*======================================*/
        #mainnav .menu-popover .nano > .nano-content{
            position: absolute
        }
        #mainnav-menu ul ul a:hover, .menu-popover .sub-menu ul ul a:hover{
            background-color: rgba(0,0,0,.0005);
        }

        .menu-popover{
            left: $mainnav-sm-width !important;
            .sub-menu{
                .pop-in{
                    display: block;
                    visibility: visible
                }
                ul{
                    background-color: darken($mainnav-bg,3%);
                    ul{
                        background-color: darken($mainnav-bg,5%);
                        > li > a{
                            padding-left: 27px;
                            &:hover{
                                color: $mainnav-hover-color
                            }
                        }
                    }
                    a{
                        &:hover{
                            color: $mainnav-hover-color
                        }
                    }
                    li{
                        >a{
                            display: block;
                            color: $mainnav-color;
                            padding: 12px 17px;
                        }
                    }
                }
            }
            .single-content{
                color: $mainnav-hover-color;
                background-color: lighten($mainnav-bg, 3%);
            }
            &.popover{
                width: 220px;
                padding: 0;
                border: 0;
                border-radius: 0 $border-radius-base  $border-radius-base 0;
                background-color: darken($mainnav-bg,2%);
                color: $mainnav-hover-color;
                position: absolute;
                box-shadow: none;
                margin-left: 0;
                &.in{
                    -webkit-animation: fadeIn .3s;
                    animation: fadeIn .3s;
                }

            }
            >.popover-title{
                border: 0;
                border-radius: 0;
                padding: 14px 20px;
                background-color: lighten($mainnav-bg, 3%);
                font-size: 1.05em;
                font-weight: $font-semibold;
                position: relative;
                z-index: 5000;
                color: $mainnav-hover-color;
                strong{
                    font-weight: 300
                }
            }
            >.popover-content{
                padding: 0;
                width: 100%;
                position: absolute;
                border-radius: 0 0 $border-radius-base 0;
                > .single-content{
                    padding: 12px 14px;
                    display: block;
                    white-space: nowrap;
                    strong{
                        font-weight: 300;
                    }
                    .label, .badge{
                        margin-left: 10px;
                        float: none !important
                    }
                }
            }
            ul{
                width: 220px;
                margin-bottom: 0
            }
            .mainnav-widget-content{
                background-color: lighten($mainnav-bg, 3%);
                > .list-group{
                    display: block;
                    width: auto
                }
            }
        }
    }
    .popover.mainnav-shortcut{
        white-space: nowrap;
        .popover-content{
            padding: 12px 15px;
        }
    }
    .mainnav-sm .popover.mainnav-shortcut{
        display: block !important;
        border: 0;
        margin-top: $shortcutHeight;
        margin-left: -1px;
        padding: 0;
        box-shadow: none;
        overflow: hidden;
        width: auto;
        max-width: 300px;
        border-radius: 0 $border-radius-base $border-radius-base 0;
        background-color: darken($mainnav-bg,2%);
        color: $mainnav-hover-color;
        left: $mainnav-sm-width !important;
        &.in{
            -webkit-animation: fadeIn .3s;
            animation: fadeIn .3s;
            .popover-content{
                height: 100%;
                margin: 0
            }
        }
        > .arrow{
            display: none;
        }
    }
}


@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}












/* NAVIGATION - OFFCANVAS */
/*======================================*/

#container.mainnav-out #content-container,
#container.mainnav-in #mainnav-container{
    left: 0;
}

#container.mainnav-in{
    #mainnav-menu-wrap{
        height: 100%
    }
}

@media (min-width: 768px){
    #container.mainnav-in{
        &.footer-fixed #footer, #content-container, #footer{
            left: $mainnav-lg-width;
        }
    }
    #container:not(.boxed-layout).mainnav-in.easeInBack #mainnav-container,
    #container:not(.boxed-layout).mainnav-in.easeOutBack #mainnav-container,
    #container:not(.boxed-layout).mainnav-in.easeInOutBack #mainnav-container,
    #container:not(.boxed-layout).mainnav-in.jumping #mainnav-container,
    #container:not(.boxed-layout).mainnav-out.easeInBack #mainnav-container,
    #container:not(.boxed-layout).mainnav-out.easeOutBack #mainnav-container,
    #container:not(.boxed-layout).mainnav-out.easeInOutBack #mainnav-container,
    #container:not(.boxed-layout).mainnav-out.jumping #mainnav-container{
        box-shadow: -20px 0 0 0 $mainnav-bg;
    }
    #container.mainnav{
        &-in{
            #navbar, .navbar-header{
                left: 0;
            }
            .tgl-menu-btn{
                right: 0;
            }
            &.aside-in{
                #aside-container{
                    right: -$mainnav-lg-width;
                }
            }
        }
        &-out{
            .brand-title{
                background-color: transparent;
                color: $brand-color-overlay;
            }
        }
    }
}




@if $mainnav-reveal == true{

    /* Reveal*/
    /*======================================*/
    @media (min-width: 768px){
        #container.reveal #content-container{
            min-height: 100vh
        }
        #container.mainnav-out.reveal #content-container,
        #container.mainnav-in.reveal #content-container{
            z-index: 7000;
        }

        #container.mainnav-in.reveal.aside-in #aside-container{
            right: -$mainnav-lg-width;
        }

        #container.mainnav-in.reveal.aside-in #footer{
            left: $mainnav-lg-width;
        }


        #container.mainnav-out.reveal #aside-container,
        #container.mainnav-in.reveal #aside-container{
            z-index: 9000;
        }


        #container.mainnav-out.reveal.aside-left:not(.aside-in) #aside-container,
        #container.mainnav-in.reveal.aside-left:not(.aside-in) #aside-container{
            left: -$aside-width;
        }

        #container.mainnav-out.reveal #footer,
        #container.mainnav-in.reveal #footer{
            z-index: 8000;
        }

        #container.mainnav-out.reveal #mainnav-container{
            left: -$mainnav-lg-width
        }


        #container.mainnav-in.reveal.slide .navbar-header:before,
        #container.mainnav-out.reveal.slide .navbar-header:before,
        #container.mainnav-out.reveal:not(.slide) #mainnav-container,
        #container.mainnav-in.reveal:not(.slide) #mainnav-container{
            left: 0;
            -webkit-transition-duration: 0;
            transition-duration: 0
        }
    }
}


@if $mainnav-slide == true{

    /* Slide in on top */
    /*======================================*/
    @media (min-width: 768px){
        #container.mainnav{
            &-in.slide{
                #navbar, #content-container, #footer{
                    left: 0
                }
                &.aside-in{
                    &.aside-left{
                        #aside-container{
                            left: 0;
                        }
                    }
                    #aside-container{
                        right: 0
                    }
                }
            }
        }
    }
}


/* NAVIGATION - BOXED LAYOUT */
/*======================================*/
@media (min-width: 1200px){
    #container.boxed-layout.mainnav-fixed #mainnav-container{
        left: auto !important;
    }
}
