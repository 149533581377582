.filters-row {
  th {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-top: none !important;
  }

  button {
    height: 32px;
  }

  button,
  a {
    i {
      font-size: 20px;
    }
  }

  input[name=filter\[id\]] {
    width: 50px;

    & {
      width: 50px;
    }
  }

  th:last-child {
    width: 190px;
  }
}

#filter {
  >.form-group {
    padding-bottom: 0;
    margin-top: 0;
  }

  #filter_without_group {
    .form-check {
      padding-top: 0;
    }
  }
}
